<template>
    <el-dialog style="margin-top: 20vh;"
               :close-on-click-modal="false"
               :show-close="false"
               @close="()=>{ $emit('input', false); $emit('close', true) }"
               :visible="value">
        <div style="display: block; text-align: center">
            <img src="@/assets/sms.png" style="text-align: center; margin-top: 15px">
            <h3 class="title33">{{ title }}</h3>
            <div>{{ message }}</div>
        </div>

        <br/>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click.prevent="()=>{$emit('ok', true); $emit('input', false); $emit('close', true)}"
                       type="primary"
                       style="margin-top: 20px; margin-bottom: 20px">{{ okText }}
            </el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: "SendMailSuccessDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "title"
        },
        message: {
            type: String,
            default: "message"
        },
        okText:{
            type: String,
            required: true
            // default: this.$t('TEXT0038')
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
    width: 400px;
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 20px);
    }
}

</style>
