<template>
    <div>
        <el-dialog :visible="value"
                   class="question-submit-dialog"
                   :close-on-click-modal="false"
                   :title="title"
                   @close="$emit('input', false)">
            <el-form :label-position="labelPosition" :label-width="labelWidth">
                <el-form-item :label="$t('TEXT0232')"
                              :error="input.error.name"
                              label-width="140px"
                              class="required"
                              prop="pass">
                    <el-input v-model="input.model.name" disabled/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0019')"
                              :error="input.error.email"
                              label-width="140px"
                              class="required"
                              prop="pass">
                    <el-input v-model="input.model.email" disabled/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0076')"
                              :error="input.error.question"
                              label-width="140px"
                              class="required">
                    <el-input v-model="input.model.question"
                              type="textarea"/>
                </el-form-item>
                <el-form-item label-width="140px">
                    <div>{{ $t('TEXT0077') }}</div>
                </el-form-item>
                <el-form-item style="text-align: center">
                    <el-button type="primary"
                               :loading="loading.questionAsk"
                               @click="askQuestion">{{ $t('TEXT0078') }}
                    </el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
        <SendMailSuccessDialog :title="$t('TEXT0118')"
                               :message="$t('TEXT0119')"
                               okText="OK"
                               @ok="$emit('input',false)"
                               v-model="popup.submitSuccess.show"/>
    </div>

</template>

<script>
import QuestionApi from "@/api/QuestionApi";
import SendMailSuccessDialog from "@/components/SendMailSuccessDialog";

export default {
    name: "QuestionSubmit",
    components: {SendMailSuccessDialog},
    computed: {
        labelPosition: function () {
            return window.innerWidth > 992 ? 'left' : "top"
        },
        labelWidth: function () {
            return window.innerWidth > 992 ? '120px' : "180px"
        },
    },
    watch: {
        value: function (val) {
            if(val) {
                this.input.model.email = this.email
                this.input.model.name = this.fullName
            }
            else {
                this.setNull(this.input.model)
                this.setNull(this.input.error)
            }
        },
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        email: {
            type: String,
            required: false
        },
        fullName: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            input: {
                model: {
                    name: null,
                    email: null,
                    question: null
                },
                error: {
                    name: null,
                    email: null,
                    question: null
                }
            },
            popup: {
                submitSuccess: {
                    show: false
                },
            },
            loading: {
                questionAsk: false
            }
        }
    },
    methods: {
        askQuestion: function () {
            this.loading.questionAsk = true
            this.setNull(this.input.error)
            QuestionApi.askQuestion(Object.assign({key: localStorage.getItem('guestKey')}, this.trimObject(this.input.model))).then(resp => {
                console.log("send success")
                this.popup.submitSuccess.show = true
                // this.$emit('input', false)
                console.log(resp)
            }).catch(resp => {
                console.log("send failed")
                if (resp.status === 412) {
                    for (let item of resp.data) {
                        console.log(item)
                        console.log(resp.data[item])
                        this.input.error[item.key] = item.value
                    }
                }
            }).finally(() => {
                this.loading.questionAsk = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
    width: 620px;
}

::v-deep textarea {
    height: 170px;
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 40px);
        margin: 0px 20px;
    }

    ::v-deep textarea {
        height: 120px;
    }

    ::v-deep .el-dialog {
        border-radius: 6px;
    }
}
</style>
