<template>
    <div v-loading="loading.kanjiDecide">
        <div class="container view-container">
            <div style="display: flex; justify-content: space-between">
                <div style="display: flex">
                    <div style="align-self: center">{{ $t('TEXT0110') }}</div>
                </div>
                <el-button type="primary" style="max-height: 40px"
                           @click="popup.questionSubmit.email = getEmail(input.model);
                           popup.questionSubmit.fullName = getFullName(input.model);
                           popup.questionSubmit.show = true">{{ $t('TEXT0074') }}
                </el-button>
            </div>

        </div>

        <div class="container view-container">
            <el-form>
                <el-row :gutter="10">
                    <el-col :lg="24" :sm="24">
                        <el-row :gutter="10">
                            <el-col :lg="5">
                                <el-form-item :error="input.error.firstName">
                                    <el-input :placeholder="$t('TEXT0058')"
                                              class="guest-name"
                                              disabled
                                              v-model="input.model.firstName">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5">
                                <el-form-item :error="input.error.countryId">
                                    <el-select :placeholder="$t('TEXT0001')"
                                               disabled
                                               v-model="input.model.countryId"
                                               style="width: 100%">
                                        <el-option v-for="(item, index) in countries" :key="index" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5">
                                <el-form-item :error="input.error.gender">
                                    <el-select :placeholder="$t('TEXT0059')"
                                               disabled
                                               v-model="input.model.gender"
                                               style="width: 100%">
                                        <el-option v-for="(val, key, index) in gender" :key="index" :value="key"
                                                   :label="$t(val)"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5">
                                <el-form-item :error="input.error.meaningId">
                                    <el-select :placeholder="$t('TEXT0004')"
                                               disabled
                                               v-model="input.model.meaningId"
                                               style="width: 100%">
                                        <el-option v-for="(item, index) in meanings"
                                                   :key="index"
                                                   :value="item.id"
                                                   :label="item.meaning"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4">
                                <el-form-item>
                                    <el-button style="width: 100%" type="primary" @click="$router.push('/top')">
                                        {{ $t('TEXT0111') }}
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider class="form-divider"/>
            <div style="margin-bottom: 20px; color: #4f4f4f">{{ $t('TEXT0192') }}</div>
            <el-row :gutter="32">
                <el-col :lg="12" :xs="24" v-for="(itemgg, indexgg) in resultParts" :key="indexgg">
                    <div @click="activeResultItem(item)"
                         v-for="(item, index) in itemgg" :key="index">
                        <el-card class="result-item-row" :class="{active: item.active}" shadow="hover"
                                 :body-style="{ padding: '0px' }">
                            <div class="result-item" style="display: flex; align-items: center">
                                <div class="left">
                                    <h3 class="kanji-font">{{ item.kanji }}</h3>
                                </div>
                                <div class="right"
                                     :style="{'background-color': item.active ? 'transparent' : 'white'}">
                                    <div>
                                        {{
                                            $t('TEXT0092') + ': ' + item.strokeCount + (getDenotation(item) ? ' (' +
                                                getDenotation(item) + ')' : '')
                                        }}
                                    </div>

                                    <div
                                        style="height:18px; margin-top: 10px; text-transform: uppercase; text-overflow: ellipsis; margin-right: 10px; width: auto !important;">
                                        <el-tooltip style="text-transform: uppercase !important"
                                                    :disabled="!getKanjiMeanings(item.kanjiList)"
                                                    :content="getKanjiMeaningUpper(item.kanjiList)">
                                            <span> {{ (getKanjiMeanings(item.kanjiList) || '') }} </span>
                                        </el-tooltip>
                                    </div>

                                    <el-tooltip :content="getDescription(item)">
                                        <div class="description">
                                            {{ getDescription(item) }}
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <!--            <LoadingDialog v-model="popup.loading.show"/>-->
        </div>


        <QuestionSubmit v-if="input.data.eightSecPassed"
                        v-model="popup.questionSubmit.show"
                        :full-name="popup.questionSubmit.fullName"
                        :email="popup.questionSubmit.email"
                        @answerSuccess="getGuestDetail()"
                        :title="$t('TEXT0074')"/>
        <SuccessDialog v-if="input.data.eightSecPassed" v-model="popup.convertError.show"
                       title=""
                       @ok="$router.replace('/')"
                       :message="popup.convertError.message"/>
        <ConfirmingDialog
                v-model="popup.activeConfirming.show"
                :title="$t('TEXT0343')"
                :message="$t('TEXT0344')"
                :yes-label="$t('TEXT0341')"
                :no-label="$t('TEXT0342')"
                :yes-btn-type="'primary'"
                :no-btn-type="'info'"
                @close="closeConfirmingPopup"
                @yes="decideKanji"
                @no="closeConfirmingPopup">
            <template v-slot:content>
                <div class="result-item" style="display: flex; align-items: center; margin-top: 10px">
                    <div class="left">
                        <h3 class="kanji-font">{{ activeItem.kanji }}</h3>
                    </div>
                    <div class="right"
                         :style="{'background-color': activeItem.active ? 'transparent' : 'white'}">
                        <div>
                            {{
                                $t('TEXT0092') + ': ' + activeItem.strokeCount + (getDenotation(activeItem) ? ' (' +
                                    getDenotation(activeItem) + ')' : '')
                            }}
                        </div>

                        <div
                            style="height:18px; margin-top: 10px; text-transform: uppercase; text-overflow: ellipsis; margin-right: 10px; width: auto !important;">
                            <el-tooltip style="text-transform: uppercase !important"
                                        :disabled="!getKanjiMeanings(activeItem.kanjiList)"
                                        :content="getKanjiMeaningUpper(activeItem.kanjiList)">
                                <span> {{ (getKanjiMeanings(activeItem.kanjiList) || '') }} </span>
                            </el-tooltip>
                        </div>

                        <el-tooltip :content="getDescription(activeItem)">
                            <div class="description">
                                {{ getDescription(activeItem) }}
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </ConfirmingDialog>
        <ChangeKanjiConfirm v-if="input.data.eightSecPassed" v-model="popup.changeKanjiConfirm.show"
                            title=""
                            :message="$t('TEXT0202')"
                            @ok="decideKanjiForce"/>
        <ChangeKanjiConfirm v-if="input.data.eightSecPassed" v-model="popup.kanjiDecidedConfirm.show"
                            title=""
                            @ok="decideOldKanji"
                            :message="$t('TEXT0203')"/>
        <div class="loading" style="display:table;" v-if="!input.data.getResultDone || !input.data.eightSecPassed">
            <div style="display: table-cell; vertical-align: middle">
                <img class="loading-image" :src="input.data.loadingImage" @load="input.data.showSearching = true"/>
                <div class="loading-text" style="text-align:center"
                     v-if="(!input.data.getResultDone || !input.data.eightSecPassed ) && input.data.showSearching">
                    <div class="search-font">
                        <span>{{ $t('TEXT0208') }}</span>
                        <span style="display: inline-block">{{ $t('TEXT0219') }}</span>
                    </div>
                    <div style="margin-top: 10px" class="search-font"> {{ $t('TEXT0218') }}</div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import CountryApi from "@/api/CountryApi";
import KanjiApi from "@/api/KanjiApi";
import constant from "@/config/constant";
import MeaningApi from "@/api/MeaningApi";
// import QuestionSubmit from "@/views/kanji/QuestionSubmit";
import GuestApi from "@/api/GuestApi";
import SuccessDialog from "@/components/SuccessDialog";
import ConfirmingDialog from "@/components/ConfirmingDialog";
import EventBus from "@/config/EventBus";
import ChangeKanjiConfirm from "@/components/ChangeKanjiConfirm";
import { isMobile } from "mobile-device-detect";
import QuestionSubmit from './QuestionSubmit';

export default {
    name: "KanjiConvertResult",
    components: { ChangeKanjiConfirm, SuccessDialog, QuestionSubmit, ConfirmingDialog },
    computed: {
        gender: function() {
            return constant.GENDER
        },
        resultParts: function() {
            let result = [[], []];
            for (let i = 0; i < this.convertResults.length; i++) {
                if (i < this.convertResults.length / 2) {
                    result[0].push(this.convertResults[i])
                } else {
                    result[1].push(this.convertResults[i])
                }
                // firstHalft.push(this.convertResults[i])
            }
            return result;
        },
        firstHalfResult: function() {
            let firstHalft = [];
            for (let i = 0; i < this.convertResults.length / 2; i++) {
                firstHalft.push(this.convertResults[i])
            }
            return firstHalft;
        },
        secondHalfResult: function() {
            let firstHalft = [];
            for (let i = Math.floor(this.convertResults.length / 2); i < this.convertResults.length; i++) {
                firstHalft.push(this.convertResults[i])
            }
            return firstHalft;
        },
        locale: function() {
            return JSON.parse(localStorage.getItem('lang') || '{}').locale == 'ja-jp' ? 'ja-jp' : 'en-us'
        }
    },
    mounted() {
        console.log("answer: ")
        console.log(this.$route.query.answerEmpty)
        localStorage.removeItem('token')
        EventBus.$emit('updateNavBar')
        if (this.$route.query.answerEmpty == "true") {
            this.popup.convertError.message = this.$t('TEXT0199')
            this.popup.convertError.show = true
        } else if (this.$route.query.answered == "true") {
            this.popup.convertError.message = this.$t('TEXT0200')
            this.popup.convertError.show = true
        } else if (this.$route.query.surveyDeleted == 'true') {
            this.popup.convertError.message = this.$t('TEXT0205')
            this.popup.convertError.show = true
        } else {
            this.popup.loading.show = true
            this.getCountries()
            this.getMeanings()
            this.getConvertResult();
            this.getGuestDetail()
        }

        if (this.$route.query.answerEmpty) {
            this.input.data.eightSecPassed = true
            // this.input.data.eightSecPassed = false
        } else {
            setTimeout(() => {
                this.input.data.eightSecPassed = true
                // this.input.data.eightSecPassed = false
            }, 8000)

            this.input.data.loadingImage = null
            this.getLoadingImage()
        }

        EventBus.$on('localeChange', () => {
            if (this.$route.path == '/kanji/convert/result') {
                this.getCountries()
                this.getMeanings()
                this.input.data.locale = JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us'
            }
        })

    },
    data() {
        return {
            input: {
                model: {
                    fullName: null,
                    firstName: null,
                    countryId: null,
                    gender: null,
                    meaningId: null,
                    email: null
                },
                data: {
                    token: null,
                    showSearching: false,
                    eightSecPassed: false,
                    getResultDone: false,
                    startImage: null,
                    loadingImage: null,
                    locale: JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us'
                },
                error: {
                    fullName: null,
                    firstName: null,
                    countryId: null,
                    gender: null,
                    meaningId: null
                }
            },
            loading: {
                kanjiDecide: false
            },
            popup: {
                loading: {
                    show: false
                },
                questionSubmit: {
                    show: false,
                    email: null,
                    fullName: null
                },
                convertError: {
                    show: false,
                    message: null
                },
                changeKanjiConfirm: {
                    show: false
                },
                kanjiDecidedConfirm: {
                    show: false
                },
                activeConfirming: {
                  show: false
                }
            },
            convertResults: [],
            countries: [],
            meanings: [],
            activeItem: null,
        }
    },
    methods: {
        decideOldKanji: function() {
            localStorage.setItem('token', this.input.data.token)
            this.getProfile(() => {
                this.$router.push("/qrcode")
            })
        },
        // getStartImage: function () {
        //     if (!isMobile) {
        //         this.input.data.startImage = require('@/assets/gif/Start_PC.gif')
        //     } else if ((JSON.parse(localStorage.getItem('lang') || '{}').locale || 'ja-jp').toLocaleLowerCase() == 'en-us') {
        //         this.input.data.startImage = require('@/assets/gif/Start_mobile_EN.gif')
        //     } else {
        //         this.input.data.startImage = require('@/assets/gif/Start_mobile_jp_E.gif')
        //     }
        //     console.log(require('@/assets/gif/Start_mobile_jp_E.gif'))
        // },
        getLoadingImage: function() {
            if (!isMobile) {
                this.input.data.loadingImage = require('@/assets/gif/1100x700__最終提出gif20210225_2(0.gif')
            } else {
                this.input.data.loadingImage = require('@/assets/gif/loadinggif20210225_2(0.15).gif')
            }
        },
        getDescription: function(item) {
            return this.input.data.locale == 'ja-jp' ? item.descriptionJp : item.descriptionEn;
        },
        getDenotation: function(item) {
            return this.input.data.locale == 'ja-jp' ? item.denotationJp : item.denotationEn;
        },
        getCountries: function() {
            CountryApi.all().then(resp => {
                this.countries = resp.data
            })
        },
        getMeanings: function() {
            MeaningApi.all().then(resp => {
                this.meanings = resp.data
            })
        },
        getConvertResult: function() {
            KanjiApi.getRomajiKanjiConvertResult({
                key: this.$route.query.token
            }).then(resp => {
                this.input.data.getResultDone = true
                if (resp.data == null) {
                    this.$router.push('/')
                } else if (resp.data.length > 0) {
                    this.convertResults = resp.data
                    this.popup.loading.show = false
                    // this.input.model.gender = resp.data.gender
                    // this.input.model.countryId = resp.data.country.id
                    // this.input.model.firstName = resp.data.firstName
                } else {
                    this.popup.convertError.show = true
                    this.popup.convertError.message = this.$t('TEXT0112')
                    // setTimeout(this.getConvertResult, 2000)
                }
                console.log("set guestKey")
                localStorage.setItem('guestKey', this.$route.query.token)
            }).catch(resp => {
                if (resp.status == 208) {// kanji already chosed
                    this.$router.push("/top");
                } else {
                    console.log(resp);
                    this.popup.convertError.message = this.$t('TEXT0205')
                    this.popup.convertError.show = true
                }
            }).finally(() => {
                this.popup.loading.show = false
            })
        },
        getGuestDetail: function() {
            GuestApi.detail({
                token: this.$route.query.token
            }).then(resp => {
                for (let item of Object.keys(this.input.model)) {
                    this.input.model[item] = resp.data[item]
                }
            })
        },
        activeResultItem: function(item) {
            this.activeItem = item;
            for (let i = 0; i < this.convertResults.length; i++) {
                if (item.id == this.convertResults[i].id) {
                    this.convertResults[i].active = true
                } else {
                    this.convertResults[i].active = false
                }
            }
            this.convertResults.__ob__.dep.notify()
            // this.convertResults = convertResults.map(item2 => {if(item2.id == item.id){item2.active = true;}else{item2.active == false}})}
            this.openConfirmingPopup();
        },
        decideKanji: function() {
            this.loading.kanjiDecide = true
            KanjiApi.decideKanji({
                id: (this.convertResults.find(item => item.active) || {}).id,
                force: false,
                token: this.$route.query.token
            }).then(resp => {
                if (resp.data == null) {
                    console.log("case 1")
                    console.log("confirm")
                    this.popup.changeKanjiConfirm.show = true
                } else if (typeof resp.data == "string") {
                    console.log("case 2")
                    // localStorage.setItem('token', resp.data)
                    // this.getProfile()
                    this.input.data.token = resp.data
                    this.popup.kanjiDecidedConfirm.show = true
                } else {
                    console.log("case 3")
                    console.log(resp)
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('showRating', true)
                    EventBus.$emit('updateNavBar')
                    this.getProfile()
                    this.$router.push('/qrcode')
                }
                // window.location.reload()
            }).finally(() => {
                this.loading.kanjiDecide = false
            })
        },
        openConfirmingPopup: function () {
          this.popup.activeConfirming.show = true;
        },
        closeConfirmingPopup: function () {
          this.popup.activeConfirming.show = false;
        },
        decideKanjiForce: function() {
            this.loading.kanjiDecide = true
            KanjiApi.decideKanji({
                id: (this.convertResults.find(item => item.active) || {}).id,
                force: true,
                token: this.$route.query.token
            }).then(resp => {
                if (resp.data == null) {
                    console.log("confirm")
                } else {
                    console.log(resp)
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('showRating', true)
                    EventBus.$emit('updateNavBar')
                    this.getProfile()
                    this.$router.push('/qrcode')
                }
                // window.location.reload()
            }).finally(() => {
                this.loading.kanjiDecide = false
            })
        },
        getProfile: function(next) {
            GuestApi.getProfile().then(resp => {
               console.log("ddddd", resp);
               localStorage.setItem('fullname',resp.data.fullName);
               localStorage.setItem('gender',resp.data.gender);
               localStorage.setItem('kanji',resp.data.kanji);
               localStorage.setItem('country',resp.data.countryId);
               localStorage.setItem('email',resp.data.email);
                EventBus.$emit('updateNavBar')
                if (next) {
                    next();
                }
            })
        },
        getKanjiMeanings(val) {
            try {
                const strs = []
                val.forEach(element => {
                    if ((element.meaning || '').trim() !== '') {
                        strs.push(element.kanji + ' : ' + element.meaning)
                    }
                });
                return strs.join(', ')
            } catch (err) {
                return ''
            }
        },
        getKanjiMeaningUpper(val) {
            try {
                const strs = []
                val.forEach(element => {
                    if (element.meaning !== null && element.meaning !== undefined) {
                        strs.push(element.kanji + ' : ' + element.meaning.toUpperCase())
                    }
                });
                return strs.join(', ')
            } catch (err) {
                return ''
            }
        },
        getFullName(val) {
            try {
                return val.fullName
            } catch (err) {
                return ''
            }
        },

        getEmail(val) {
            try {
                return val.email
            } catch (err) {
                return ''
            }
        },
    }
}
</script>

<style scoped lang="scss">
@import "src/style/variables";


.description {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto !important;
    margin-right: 10px;
    line-height: 20px;
}

//::v-deep .el-form-item {
//    margin-bottom: 16px;
//}
.kanji-font {
    font-size: 24px;
    font-weight: 500;
    font-family: "Noto Serif CJK JP" !important;
}

::v-deep .guest-name input {
    text-transform: uppercase !important;
}

.result-item-row {
    margin-bottom: 15px;
}


.result-item-row.active {
    background-color: #91dcff
}

.result-item {
    padding-bottom: 0px;
    cursor: pointer;
    display: table;
    background-color: rgba(223, 224, 235, 0.2);

}

.result-item .left {
    color: #000000;
    font-weight: 700;
    width: 30%;
    min-width: 30%;
    vertical-align: middle;
    display: table-cell;
}

.result-item .right {
    text-align: left;
    border-left: 1px solid #e5e5e5;
    background-color: white;
    display: table-cell;
    width: 70%;
    padding: 10px;
}

.result-item .left h3 {
    height: 100%;
    line-height: 100%;
}

.result-item .left h3 {
    text-align: center;
    vertical-align: middle;
}


.result-item .right > h3, .title {
    color: #4F4F4F;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-item .right > div {
    color: #4F4F4F;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}


.col-kanji-decide {
    text-align: right;
}

.search-font {
    font-size: 30px !important;
}

@media screen and (max-width: 991px) {
    .col-kanji-decide {
        text-align: center;
    }
    .kanji-font {
        font-size: 20px;
        font-family: "Noto Serif CJK JP" !important;
    }
    .search-font {
        font-size: 20px !important;
    }
}

.form-divider {
    margin-top: 0px;
    margin-bottom: 20px;
}

::v-deep .el-input__inner[disabled] {
    color: #4F4F4F;
    -webkit-text-fill-color: #4F4F4F;
    opacity: 1 !important;
    background-color: white !important;
}

::v-deep .el-input__inner[disabled]::placeholder {
    color: #4F4F4F;
    -webkit-text-fill-color: #4F4F4F;
    opacity: 1 !important;
}

.view-container {
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
}

.loading {
    background-color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.loading-image {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 70vh;
}
</style>
